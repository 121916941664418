<template>
  <div class="Maps">
    <GmapMap
        :center="center"
        :zoom="12"
        style="width:100%; height: 100%;"
        :options="{
         zoomControl: false,
         mapTypeControl: false,
         scaleControl: false,
         streetViewControl: false,
         rotateControl: false,
         fullscreenControl: false,
         disableDefaultUi: false
 }"
    >
      <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click=getHouseSelected(index)
      />
    </GmapMap>
    <img src="" alt="">
    <div class="modal-overlay" v-if="showModal" @click="showModal = false"></div>
    <div class="modal close" v-if="showModal">
    <button class="btn-close" @click="cerrar"></button>
      <h1 style="text-align: center">{{actualHouse.title}}</h1>
     <center> <img :src=actualHouse.image style="height: 200px; width: 300px" ></center>
      <p>{{actualHouse.description}}</p>
      <p style="size: 20px"><b>${{actualHouse.price}}</b></p>
     <center>  <button class="modalButton" @click = sendId()  >
        Más información
     </button> </center>
    </div>
  </div>

</template>

<script>
const home = {lat: 28.622321, lng: -106.081392}
import axios from 'axios'
export default {
  name: 'MapsView',
  data() {
    return {
      markers: [],
      response: [],
      actualHouse: [],
      coords:[],
      showModal:false,
      center: home,
      indexDad: 0
    }

  },
  props:{
    param:Object,
    respuesta:Object
  },
  methods:{
    cerrar: function (){
      this.showModal= false
    },
    getHouses:async function (respuesta){
      if(Object.entries(respuesta).length==0) {
        console.log("aqui")
        console.log(respuesta)
        this.response = await axios.post('https://real-red-goat-yoke.cyclic.app/houses/find',{},  {headers:
        {
          Authorization: "Bearer " + window.localStorage.getItem('token')
          }
        })
        console.log("marquer",this.response.data.obj)
        this.response.data.obj.forEach(element => [
          this.coords = {position: {lat: element.location.lat, lng: element.location.lng}},
          this.markers.push(this.coords)
        ])

        console.log(this.markers[1].position)
      }
      else {
        this.respuesta.forEach(element => [
          this.coords = {position: {lat: element.location.lat, lng: element.location.lng}},
          this.markers.push(this.coords)
        ])

        console.log(this.markers[0].position)
      }
    },
    getHouseSelected: function (index){
      console.log(this.response.data.obj[index])
      this.showModal= true
      this.actualHouse =this.response.data.obj[index]
      this.indexDad = index
    },
    sendId:function () {
     this.$router.push({name:"HouseView", params: { id: this.response.data.obj[this.indexDad]._id }})
       //params:{id:"id="+this.response.data.obj[0]._id}}

    }

  },
  async mounted() {
    await this.getHouses(this.respuesta)
  },

  components:{

  }
}

</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

body {
  font-family: 'montserrat', sans-serif;
}

#app {
  position: relative;

  display: flex;

  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.modalButton {
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  display: inline-block;
  padding: 15px 25px;
  background-image: linear-gradient(to right, #00bcd4, #22EFE8);
  border-radius: 8px;

  color: #FFF;
  font-size: 18px;
  font-weight: 700;

  box-shadow: 3px 3px rgba(0, 0, 0, 0.4);
  transition: 0.4s ease-out}


.modal-overlay {

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  display: block;
  position: fixed !important;
  top: 50% !important;
  left: 50%!important;
  transform: translate(-50%, -50%)!important;
  z-index: 99!important;

  width: 100%!important;
  max-width: 400px!important;
  background-color: #FFF!important;
  border-radius: 16px!important;
height: fit-content;
  padding: 25px!important; }

h1 {
  color: #222;
  font-size: 32px;
  font-weight: 500 !important;
  margin-bottom: 15px;
  text-align: center;
}

p {
  color: #666;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  text-align: center;
}


</style>
