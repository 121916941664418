<template>

  <div class="vue-template log ">
    <h3>Sign Up</h3>

      <div class="row">
        <label class="col-6">Nombre</label>
        <label class="col-6">Apellido</label>
        <div class="col-6">
            <input type="text" v-model="registro.fn" class="form-control" />
        </div>
        <div class="col-6">
        <input type="text" v-model="registro.ln" class="form-control"/>
        </div>
      </div>
      <div class="form-group">
        <label>Email address</label>

        <input type="email" v-model="registro.un" class="form-control form-control-lg" />

      <div class="form-group">
        <label>Password</label>
        <input type="password" v-model="registro.pss" v-on:change=validate(registro)  class="form-control form-control-lg" style="
      margin-bottom: 15px"/>
      </div>
      </div>
      <button  v-on:click="generaSinUp()" class="btn btn-dark btn-lg btn-block">
        Sign Up
      </button>

      <p class="forgot-password text-right">
        Ya registrado
        <router-link :to="{ name: 'signInView' }">sign in?</router-link>
      </p>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      registro: {
        fn: '',
        un: '',
        pss: '',
        ln: ''
      },
      val: false
    }

  },

  methods: {
    validate(dat) {
      let th = this
      if (dat.userPass.length >=10) {
        console.log(dat.userPass.length);
        th.val = true
      } else {
        th.val = false
      }
    },
    async generaSinUp() {
      let th = this
        let emailRegex = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (emailRegex.test(th.registro.userName)) {
          let response = await axios.post('https://real-red-goat-yoke.cyclic.app/users/create', th.registro)
          if (response.status === 200) {
            await th.$router.push({name: 'signInView'})
          }else if(response.status >= 500){
            alert("Hubo un error")
          }else{
            alert("Intenta otra vez")
          }
          console.log(response);
        }
      }
  }
}
</script>
<style >

</style>