<template>
  <div>
    <div style="margin: 2%">
      <div class="row">
        <div class="col-7" >
          <h4 style="color: green">{{response.buildingType}}</h4>
          <h1>{{response.title}}</h1>
          <h5>{{response.description}}</h5>
          <p></p>
          <p>Dirección: {{response.address}}</p>
        </div>
        <div class="col-5" style="">
          <h4 style="text-align: end;color:orangered">{{response.tipo}}</h4>
          <h1 style="text-align: end">${{response.price}}</h1>
        </div>

        <img v-if="response.image.length != 0" class="card-img-top" :src="response.image" alt="Card image cap" style="height: 362px">
        <img v-else class="card-img-top" src="https://img.vivanuncios.com.mx/api/v1/mx-ads/images/7b/7b9b244c-abd1-40dc-8a8f-21678f53a7d6?rule=s-I72.auto" alt="Card image cap" style="height: 362px">

        <div class="col-12" style="background-color: #00bcd4">
        <h2>Datos de contacto:</h2>
          <p><b>Nombre de contacto:</b> {{response.nombre}}</p>
          <p><b> de contacto:</b> {{response.telefono}}</p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "HouseView",
  props:{
  },
  components:{

  },
  data() {
    return {
      response:{}
    }
  },
  methods:{
    getHouse:async function(){
      console.log(this.$route.params.id)
      let responses = await axios.post("https://real-red-goat-yoke.cyclic.app/houses/find",{_id:this.$route.params.id},{
        headers:{
          Authorization: "Bearer " +
              ""+window.localStorage.getItem('token')
        }
      })
      this.response = responses.data.obj[0]
      console.log("hola",this.response)
    }
  },
  async mounted() {
   await this.getHouse()
  }
}
</script>

<style scoped>

</style>