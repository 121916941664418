<template>
  <div>
    <ul class="pater" style="padding-left: 18% ;list-style: none">
      <li class=" dropdown background">
        <a class="dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Opcion</a>
        <div class="dropdown-menu dropdown-menu-right dropdown-cyan" >
          <button class="dropdown-item" @click="filtrarCasa('dealType','Venta')" >Venta</button>
          <button class="dropdown-item" @click="filtrarCasa('dealType','Renta')" >Renta</button>
        </div>
      </li>
      <li class="dropdown background">
        <a class="dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tipo</a>
        <div class="dropdown-menu dropdown-menu-right dropdown-cyan" >
          <button class="dropdown-item" @click="filtrarCasa('buildingType','Casa')" >Casa</button>
          <button class="dropdown-item" @click="filtrarCasa('buildingType','Departamento')" >Departamento</button>
        </div>
      </li>
      <li class="dropdown background">
        <a class="dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Precio Min </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-cyan" >
          <input  class="dropdown-item" style="border-bottom: 0" placeholder="Ingresa valor min"  v-model="respuesta.minPrice">
        </div>
      </li>
      <li class=" dropdown background">
        <a class="dropdown-toggle width"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Precio Max </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-cyan" >
          <input  class="dropdown-item" style="border-bottom: 0" placeholder="Ingresa valor max" v-model="respuesta.maxPrice">
        </div>
      </li>
      <li class="  background">
        <button style="border: none" @click="limpiar()"> Limpiar filtros </button>
      </li>
      <li class="  background">
        <button style="border: none" @click="Buscar()"> Aplicar filtros </button>
      </li>
    </ul>
    <div class="grid-container" style="min-height: 800px">
      <OptionsView class="grid-item"
      :Casas=response
      />
      <MapsView class="grid-item"
       :respuesta=cord
                :key="index"
      ></MapsView>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import OptionsView from "@/components/OptionsView";
import MapsView from "@/views/MapsView";
export default {
  name: "StartView",
  components: {
    MapsView,
    OptionsView
  },
  data() {
    return {
      respuesta:{},
      response: {},
      cord: {},
      index: 0
    }
  },
  async created() {
    await this.Buscar()
  },
  methods: {
    filtrarCasa(param, tipo) {

      this.respuesta[param]=tipo
      this.Buscar()
    }
    ,
    limpiar() {
      this.respuesta = {}
      this.Buscar()
      console.log(this.respuesta)
    },
    async Buscar() {
      console.log(JSON.stringify( this.respuesta))
      this.response = await axios.post('https://real-red-goat-yoke.cyclic.app/houses/find',this.respuesta,{
        headers:{
          Authorization: "Bearer "+window.localStorage.getItem('token')

        }
      })
      this.cord = this.response.data.obj
      console.log(this.cord)
    }
  }
}
</script>

<style scoped>
  .grid-container{
    display: grid;
    grid-template-columns: 40% 60% ;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);

    padding: 20px;
    font-size: 30px;
    text-align: center;
  }
</style>