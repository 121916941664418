<template>
  <div>
    <div class="grid-container" v-for="(casa,index) in favourite.data.obj.favorites" :key="index">
  <div class="card-deck grid-item">
      <div class="card">
        <i class="bi bi-heart" style="position: absolute;left: 93%"> <button @click="agregarFav(casa._id)" style="background: none; border: none"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-heart-half hovere" viewBox="0 0 16 16">
          <path d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1
            .176-.17C12.72-3.042 23.333 4.867 8 15z"/> </svg></button></i>
        <img v-if="casa.image" class="card-img-top" :src="casa.image" alt="Card image cap" style="height: 362px">
        <img v-else class="card-img-top" src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.archdaily.mx%2Fmx%2F950069%2Fcasa-z-mda-arquitectura&psig=AOvVaw3NxEedRNG-qwgiYo471d9N&ust=1668504146903000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCMDnprysrfsCFQAAAAAdAAAAABAE" alt="Card image cap" style="height: 362px">
        <div class="card-body">
          <h3 class="card-title" v-if="casa.tipo=='Renta'">{{casa.title}} En Renta</h3>
          <h3 class="card-title" v-else>{{casa.title}} En Venta</h3>
          <p class="card-text">{{casa.description}}<br>Ubicada en: {{casa.address}}</p>
          <p class="card-text"><small class="text-muted">{{casa.address}}</small></p>
        </div>
      </div>

      <br>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FavoritosView",
  data(){
    return{
      favourite:[]
    }
  },
   mounted() {
   this.cargarFavs()
  },
  methods:{
    cargarFavs: async function(){
      var response = await axios.post('https://real-red-goat-yoke.cyclic.app/users/findFavorites',{user_id:window.localStorage.getItem('userId')})
      this.favourite = response
      console.log(response.data.obj.favorites)
    },
    async agregarFav(index){
      let respuesta =  await axios.post('https://real-red-goat-yoke.cyclic.app/users/find/',{_id:window.localStorage.getItem('userId')})
      if(respuesta.data.obj[0].favorites.includes(index)) {
        console.log(respuesta.data.obj[0].favorites )
        await axios.post('https://real-red-goat-yoke.cyclic.app/users/removeFavorite',{us_id:window.localStorage.getItem('userId'),house_id:index})
        alert("Se elimino de favoritos")
      }
      else
      {
        await axios.post('https://real-red-goat-yoke.cyclic.app/users/addFavorite',{us_id:window.localStorage.getItem('userId'),house_id:index})
        alert("agregado a favoritos")

      }
    }
  }
}
</script>

<style scoped>
.grid-container{
  justify-content: center;
}
.grid-item {

  background-color: rgba(255, 255, 255, 0.8);
  width: max-content;
  margin-left: 4%;
  font-size: 30px;
  text-align: center;
  float: left;
}

</style>