<template>
<div>
 <div class="card-deck">
    <div v-for="(casa,index) in Casas.data.obj" :key="index">
        <div class="card">
         <i class="bi bi-heart" style="position: absolute;left: 93%">  <button @click="agregarFav(casa._id)" style="background: none; border: none"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-heart-half hovere" viewBox="0 0 16 16">
            <path d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1
            .176-.17C12.72-3.042 23.333 4.867 8 15z"/> </svg></button></i>
          <router-link :to="{name:'HouseView',params:{id:casa._id}}">

          <img v-if='casa.image[0]' class="card-img-top" :src="casa.image" alt="Card image cap" style="height: 362px">
            <img v-else class="card-img-top" src="https://img.vivanuncios.com.mx/api/v1/mx-ads/images/7b/7b9b244c-abd1-40dc-8a8f-21678f53a7d6?rule=s-I72.auto" alt="Card image cap" style="height: 362px">
          <div class="card-body">
            <h2 class="card-title" v-if="casa.houseType=='Renta'">{{casa.title}} En Renta</h2>
            <h2 class="card-title" v-else>{{casa.title}} En Venta</h2>
            <p class="card-text">{{casa.description}}<br>Ubicada en: {{casa.address}}</p>
            <p class="card-text"><small class="text-muted">{{casa.address}}</small></p>
          </div>
          </router-link>
        </div>
      <br>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "OptionsView",
  props:{
    Casas: Object
  },
  data(){
    return{
      fav : false
    }
  },
  methods:{
   async agregarFav(index){
     let respuesta =  await axios.post('https://real-red-goat-yoke.cyclic.app/users/find/',{_id:window.localStorage.getItem('userId')})
     if(respuesta.data.obj[0].favorites.includes(index)) {
       console.log(respuesta.data.obj[0].favorites )
       await axios.post('https://real-red-goat-yoke.cyclic.app/users/removeFavorite',{us_id:window.localStorage.getItem('userId'),house_id:index})
       alert("Se elimino de favoritos")
     }
     else
     {
       await axios.post('https://real-red-goat-yoke.cyclic.app/users/addFavorite',{us_id:window.localStorage.getItem('userId'),house_id:index})
       alert("agregado a favoritos")

     }
    }
  }
}
</script>

<style scoped>
.hovere{
color: red;
}
.hovere:hover{
  --size: 200px;
  color: red;
  transform:rotate(35deg);
  animation:beat 10s infinite alternate ease-in-out;

}

</style>