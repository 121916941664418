import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SingUpView from "@/views/SingUpView";
import SignInView from "@/views/SignInView";
import StartView from "@/views/StartView"
import FavoritosView from "@/views/FavoritosView";
import MapsView from "@/views/MapsView";
import HouseView from "@/views/HouseView";
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/start',
        name: 'start',
        component: StartView
      },
      {
        path: '/house/:id',
        name: 'HouseView',
        component: HouseView
      },
      {
        path: '/fav',
        name: 'fav',
        component: FavoritosView
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:"/signUp",
    name: "signUpView",
    component: SingUpView
  },
  {
    path:"/",
    name: "signInView",
    component: SignInView
  },
  {
    path: "/maps",
    name: "mapsView",
    component: MapsView
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
