<template>
  <div class="log">
    <h3>Sign in</h3>

    <div class="form-group">
      <label>Email address</label>
      <input type="email" class="form-control form-control-lg" v-model="user"/>
    </div>

    <div class="form-group">
      <label>Password</label>
      <input type="password" class="form-control form-control-lg" style="margin-bottom: 15px" v-model="pass"/>
    </div>

    <button  class="btn btn-dark btn-lg btn-block" v-on:click = loginUser()>
      Sign In
    </button>
    <p class="forgot-password text-right">
      Aun sin registrar
      <router-link :to="{ name: 'signUpView' }">sign up?</router-link>
    </p>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "SignInView",
  data() {
    return {
      user: "",
      pass: "",
      status: 0
    }
  },

  methods: {
    loginUser: async function(){
      try{
        var response = await axios.post("https://real-red-goat-yoke.cyclic.app/users/login", {
          user: this.user,
          pass: this.pass
        })
        console.log(response)
      } catch (e){
        console.log(e)
      }

    if(response.status==200){
      window.localStorage.setItem('token', response.data.authToken);
      window.localStorage.setItem('userId', response.data.obj._id);
      this.$router.push({
        name: 'start'})
    }
      console.log(response)
    }
  }
}
</script>

<style scoped>

</style>